import { Component, Inject, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from "@angular/core";
import { MapComponent } from "@app/components/map/map.component";
import { MapDrawComponent } from "@app/components/map/map-draw.component";
import * as L from "leaflet";
import { WindyService } from "@app/services/map/windy.service";
import "leaflet-rotatedmarker";
import { Subscription } from "rxjs";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-map-windy",
  templateUrl: "./map-windy.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./map-windy.component.scss"],
  providers: [{ provide: MapDrawComponent, useExisting: MapWindyComponent }],
})
export class MapWindyComponent extends MapDrawComponent implements OnInit, OnDestroy {
  @Input() parent: MapComponent;

  // z-indexes are calculated weirdly, set vessel much higher in order to be on top
  private readonly vesselLayerZIndex = 50;
  private readonly selectedVesselLayerZIndex = 1;

  private map: L.Map;
  private vesselLayer = L.featureGroup();
  private selectedVesselLayer = L.featureGroup();
  private movedToVessel: boolean = false;

  private picker: any;

  private subscriptions: Subscription[] = [];

  constructor(
    private windyService: WindyService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit(): void {
    this.prepareMap();
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  drawVessels(vessels: any) {
    this.vesselLayer.clearLayers();
    for (const vessel of vessels) {
      const longitude = vessel.longitude ?? 0;
      const latitude = vessel.latitude ?? 0;
      if (Number.isNaN(longitude) || Number.isNaN(latitude)) {
        continue;
      }
      const heading = vessel.heading;
      const rotation = !Number.isNaN(heading) ? Math.round(((heading * Math.PI) / 180) * 100) / 100 : 0;
      const steaming = vessel.steaming;
      const color = vessel.alarmAlerts ? "#f01716" : vessel.warningAlerts ? "#ffba00" : vessel.infoAlerts ? "#73828e" : "#003c80";
      const useArrow = typeof rotation === "number" && steaming;
      const arrow = encodeURIComponent(`<?xml version="1.0" encoding="UTF-8"?>
<svg width="25px" height="32px" viewBox="0 0 25 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>Vessel</title>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path id="vessel" fill="${color}" d="M0.0571213445,30.845666 C11.0571213,0.845666045 0.0571213445,30.845666 11.0571213,0.845666045 C11.4664822,-0.270772572 12.6396287,-0.292950266 13.0571213,0.845666045 C24.0571213,30.845666 13.0571213,0.845666045 24.0571213,30.845666 C24.3206559,31.5643967 23.6171646,32.1256877 23.0571213,31.845666 C13.0571213,26.845666 23.0571213,31.845666 13.0571213,26.845666 C12.129685,26.3819478 11.9845577,26.3819478 11.0571213,26.845666 C1.05712134,31.845666 11.0571213,26.845666 1.05712134,31.845666 C0.49724236,32.1256055 -0.206611335,31.564937 0.0571213445,30.845666 Z"></path>
  </g>
</svg>`);
      const dot = encodeURIComponent(`<?xml version="1.0" encoding="UTF-8"?>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Vessel</title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle id="vessel" fill="${color}" cx="10" cy="10" r="10"></circle>
        </g>
        </svg>`);
      const markerIcon = useArrow ? arrow : dot;
      const marker = L.marker([latitude, longitude], {
        icon: L.icon({
          iconUrl: `data:image/svg+xml;utf8,${markerIcon}`,
          iconSize: useArrow ? [25, 32] : [20, 20],
        }),
        rotationAngle: heading,
        rotationOrigin: "center center",
        zIndexOffset: this.vesselLayerZIndex,
      });
      marker.bindTooltip(vessel.name, {
        className: "tooltip",
        direction: "top",
        offset: [0, -10],
      });
      marker.on("click", (event) => {
        if (this.parent.selectedVessel) {
          this.clearSelectedVessel();
        }
        this.parent.onMapClicked(vessel.id, "map");
      });
      this.vesselLayer.addLayer(marker);
    }
    this.vesselLayer.setZIndex(this.vesselLayerZIndex);
  }

  drawSelectedVessel() {
    if (!this.parent.selectedVessel) {
      return;
    }
    this.clearSelectedVessel();
    const longitude = this.parent.selectedVessel.longitude ?? 0;
    const latitude = this.parent.selectedVessel.latitude ?? 0;
    const marker = L.marker([latitude, longitude], {
      icon: L.divIcon({
        html: '<div class="selected-vessel-marker"></div>',
      }),
      zIndexOffset: this.selectedVesselLayerZIndex,
    });
    this.selectedVesselLayer.addLayer(marker);
    this.selectedVesselLayer.setZIndex(this.selectedVesselLayerZIndex);
  }

  clearSelectedVessel() {
    this.selectedVesselLayer.clearLayers();
  }

  clearTrail() {}

  drawTrail() {}

  moveTo(vessel: any) {
    if (!vessel) {
      return;
    }
    if (this.map) {
      let longitude = vessel.longitude;
      let latitude = vessel.latitude;
      if (!longitude) {
        longitude = 0;
      }
      if (!latitude) {
        latitude = 0;
      }
      const vesselPoint = this.map.latLngToLayerPoint([latitude, longitude]);
      this.movedToVessel = true;
      this.map.panTo(this.map.layerPointToLatLng(vesselPoint), {
        duration: 1.5,
      });
      this.picker.open({
        lat: latitude,
        lon: longitude,
      });
    }
  }

  startRoute() {}

  stopRoute() {}

  private prepareMap() {
    this.parent.setMapLoading(true);
    this.subscriptions.push(
      this.windyService.initialize(this.renderer).subscribe({
        next: (windyApi) => {
          const { map, overlays, store, broadcast, picker } = windyApi;

          overlays.wind.setMetric("bft");
          store.set("lang", "en");
          store.set("hourFormat", "24h");

          this.map = map;

          L.tileLayer(MapWindyComponent.LAYER_SEAMARK, {
            attribution: '&copy; <a href="https://openseamap.org/">OpenSeaMap</a>',
            maxZoom: 18,
          }).addTo(map);
          this.selectedVesselLayer.addTo(map);
          this.vesselLayer.addTo(map);

          map.on("click", () => this.parent.onMapClicked(-1, ""));
          map.on("moveend", () => {
            if (this.movedToVessel) {
              const center = this.map.getCenter();
              this.movedToVessel = false;
              this.drawSelectedVessel();
            }
          });
          map.on("zoomend", () => this.storage.set(MapDrawComponent.ZOOM_STORAGE_KEY, map.getZoom()));

          this.picker = picker;
          broadcast.once("redrawFinished", () => {
            if (this.parent.selectedVessel) {
              this.picker.open({
                lat: this.parent.selectedVessel.latitude ?? 0,
                lon: this.parent.selectedVessel.longitude ?? 0,
              });
            } else {
              const bounds = map.getBounds();
              const center = map.getCenter();
              this.picker.open({
                // set picker on right border
                lat: (bounds.getNorthEast().lat + bounds.getSouthEast().lat) / 2,
                lon: (bounds.getNorthEast().lng - center.lng) * 0.8 + center.lng,
              });
            }
          });

          if (this.parent.selectedVessel) {
            this.moveTo(this.parent.selectedVessel);
            if (this.storage.has(MapDrawComponent.ZOOM_STORAGE_KEY)) {
              const zoom = this.storage.get(MapDrawComponent.ZOOM_STORAGE_KEY);
              this.map.setZoom(zoom);
            }
          } else {
            map.fitWorld();
          }

          this.parent.setMapLoading(false);
        },
        error: (error) => {
          alert("Weather data not available: " + error);
          this.parent.toggleWeather(false);
        },
      })
    );
  }
}
