import { ApiService } from "@app/services/api/api.service";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TrackRangeService } from "@app/services/ranges/track-range.service";
import { VesselGroupsService } from "@app/services/vessel-groups/vessel-groups.service";
import { DateRange } from "@app/helpers/ranges";
import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from "@angular/core";
import { DatePickerOptions } from "@app/components/date-picker/date-picker.component";
import { MapDrawComponent } from "@app/components/map/map-draw.component";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly vesselPopupSize = 450;
  readonly datePickerOptions: DatePickerOptions = {
    drops: "up",
    opens: "right",
  };
  thisComponent = this;
  datePickerSelected: DateRange = undefined;
  selectedVessel: any;
  selectedVesselDetails: any = {};
  detailsMeasuredAt: number;
  fullScreen: boolean = false;
  weatherEnabled: boolean = false;
  routeEnabled: boolean = false;
  aisEnabled: boolean = false;

  @ViewChildren(MapDrawComponent)
  mapDrawComponents: QueryList<MapDrawComponent>;
  private mapDrawComponent: MapDrawComponent;

  protected subscriptions: Subscription[] = [];

  private initialized = false;
  private mapLoading = true;
  private loading = false;
  private layerOpen = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private trackRangeService: TrackRangeService,
    private vesselGroupsService: VesselGroupsService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.apiService
      .initialize()
      .toPromise()
      .then(() => {
        this.subscriptions.push(
          this.trackRangeService.getRangeSubject().subscribe({
            next: (dateRange) => {
              this.datePickerSelected = dateRange;
              this.updateVesselDetails();
            },
          }),

          this.vesselGroupsService.initialize().subscribe({
            next: () => {
              this.updateVessels();
              this.selectedVessel = this.vesselGroupsService.getSelectedVessel();
            },
          }),

          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.selectedVessel = this.vesselGroupsService.getSelectedVessel();
              this.updateVesselDetails();
              this.showSelectedVessel();
            }
          })
        );
      });
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.mapDrawComponent = this.mapDrawComponents.first;
    this.mapDrawComponents.changes.subscribe({
      next: () => {
        this.mapDrawComponent = this.mapDrawComponents.first;
        this.mapDrawComponent.drawVessels(this.vesselGroupsService.getVessels());
        this.mapDrawComponent.drawTrail();
      },
    });
  }

  @HostListener("document:fullscreenchange", []) onFullScreenChange() {
    this.fullScreen = !!document.fullscreenElement;
  }

  toggleFullScreen(fullScreen: boolean) {
    if (fullScreen) {
      document.getElementById("app-map").requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  setMapLoading(loading: boolean) {
    this.mapLoading = loading;
  }

  isMapLoading(): boolean {
    return this.mapLoading;
  }

  isLoading(): boolean {
    return this.loading;
  }

  isLayerOpen(): boolean {
    return this.layerOpen;
  }

  closeLayer() {
    this.layerOpen = false;
  }

  toggleWeather(weather: boolean) {
    this.weatherEnabled = weather;
  }

  toggleRoute(route: boolean) {
    this.routeEnabled = route;
    if (this.routeEnabled) {
      this.mapDrawComponent.startRoute();
    } else {
      this.mapDrawComponent.stopRoute();
    }
  }

  toggleAis(ais: boolean) {
    this.aisEnabled = ais;
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.trackRangeService.setRange(dateRange);
  }

  onMapClicked(vesselId: number, route: string) {
    if (vesselId >= 0) {
      this.router.navigate([route, vesselId]);
    } else {
      this.layerOpen = false;
    }
  }

  getVesselOverlayContent(vesselId: number): string {
    const vessels = this.vesselGroupsService.getVessels().filter((item) => item.id === vesselId);
    const vesselName = vessels.length > 0 ? vessels[0].name : "";
    return `<div>${vesselName}</div>`;
  }

  getTrailOverlayContent(timestamp: number): string {
    let sog = 0,
      tws = 0;
    const item = this.selectedVesselDetails.positions.find((item) => item.measuredAt === timestamp);
    if (item != undefined) {
      sog = item.sog_kn;
      tws = item.tws_Bft;
    }
    const formattedDate = this.datePipe.transform(new Date(timestamp), "yyyy-MM-dd HH:mm:ss", "UTC");
    return `<div>${formattedDate}</div>
            <div>SOG: ${sog.toFixed(1)} kn</div>
            <div>TWS: ${tws ? tws : "-"} Bft</div>`;
  }

  private showSelectedVessel() {
    this.layerOpen = !!this.selectedVessel;
    if (this.selectedVessel) {
      this.mapDrawComponent.moveTo(this.selectedVessel);
    } else {
      this.mapDrawComponent.clearSelectedVessel();
    }
  }

  private updateVessels(): void {
    const selectedVesselId = this.vesselGroupsService.getSelectedVesselId();
    if (!this.initialized || (selectedVesselId && !this.selectedVessel)) {
      this.selectedVessel = this.vesselGroupsService.getSelectedVessel();
      this.initialized = true;
      this.layerOpen = !!this.selectedVessel;
    }

    const vessels = this.vesselGroupsService.getVessels();
    if (vessels.length) {
      this.mapDrawComponent.drawVessels(vessels);
    }

    if (this.selectedVessel) {
      this.updateVesselDetails();
      this.mapDrawComponent.moveTo(this.selectedVessel);
    }
  }

  private updateVesselDetails() {
    if (!this.selectedVessel) {
      return;
    }
    this.loading = true;
    this.apiService
      .initialize()
      .toPromise()
      .then(() => {
        const href = this.apiService.resolveLink(this.selectedVessel._links.self.href, {
          from: [this.datePickerSelected.start * 1000],
          to: [this.datePickerSelected.end * 1000],
        });

        this.apiService
          .get(href)
          .toPromise()
          .then((data) => {
            if (this.selectedVessel && data.id === this.selectedVessel.id) {
              this.selectedVesselDetails = Object.assign(this.selectedVessel, data);
              this.detailsMeasuredAt = undefined;
              for (const popupDataValue of this.selectedVesselDetails.popupDataValues) {
                if (!this.detailsMeasuredAt || popupDataValue.measuredAt > this.detailsMeasuredAt) {
                  this.detailsMeasuredAt = popupDataValue.measuredAt;
                }
              }
              this.mapDrawComponent.drawTrail();
              this.loading = false;
            }
          });
      });
  }
}
