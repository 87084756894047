import { Component, Input } from "@angular/core";

@Component({
  selector: "app-hull-condition-report",
  templateUrl: "./hull-condition-report.component.html",
  styleUrls: ["./hull-condition-report.component.scss"],
})
export class HullConditionReportComponent {
  @Input() hullConditionReport: any | undefined = undefined;

  constructor() {}
}
