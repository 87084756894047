import { Component, Input } from "@angular/core";
import { MapComponent } from "@app/components/map/map.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MapDrawComponent } from "@app/components/map/map-draw.component";

@Component({
  selector: "app-map-ais",
  templateUrl: "./map-ais.component.html",
  styleUrls: ["./map-ais.component.scss"],
  providers: [{ provide: MapDrawComponent, useExisting: MapAisComponent }],
})
export class MapAisComponent extends MapDrawComponent {
  @Input() parent: MapComponent;

  vesselFinderUrl: SafeResourceUrl;

  private readonly baseUrl = "https://www.vesselfinder.com/aismap";

  constructor(private sanitizer: DomSanitizer) {
    super();
    this.vesselFinderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUrlWithParameters(null));
  }

  drawVessels(vessels: any) {
    const url = this.getUrlWithParameters(this.parent.selectedVessel);
    this.vesselFinderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  drawSelectedVessel() {}

  clearSelectedVessel() {}

  moveTo(vessel: any) {
    const url = this.getUrlWithParameters(vessel);
    this.vesselFinderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  clearTrail() {}

  drawTrail() {}

  startRoute() {}

  stopRoute() {}

  private getUrlWithParameters(vessel: any) {
    const latitude = vessel?.latitude ?? 0;
    const longitude = vessel?.longitude ?? 0;
    const zoom = vessel ? 11 : 0;
    return this.baseUrl.concat(`?lat=${latitude}&amp;lon=${longitude}&amp;names=true&amp;zoom=${zoom}`);
  }
}
