<div *ngIf="hullConditionReport === undefined" class="spinner h-20rem"></div>

<div *ngIf="hullConditionReport !== undefined">
  <div class="separator">
    <h2
      class="pl-2"
      [ngClass]="{
        'green-underline':
          hullConditionReport.mainEngineFuelOverconsumption_t_per_d &&
          (hullConditionReport.mainEngineFuelOverconsumption_t_per_d / hullConditionReport.mainEngineFuelConsumptionRateBaseline_t_per_d) * 100 < 5,
        'yellow-underline':
          hullConditionReport.mainEngineFuelOverconsumption_t_per_d &&
          (hullConditionReport.mainEngineFuelOverconsumption_t_per_d / hullConditionReport.mainEngineFuelConsumptionRateBaseline_t_per_d) * 100 >= 5 &&
          hullConditionReport.mainEngineFuelOverconsumption_t_per_d / hullConditionReport.mainEngineFuelConsumptionRateBaseline_t_per_d <= 10,
        'red-underline':
          hullConditionReport.mainEngineFuelOverconsumption_t_per_d &&
          (hullConditionReport.mainEngineFuelOverconsumption_t_per_d / hullConditionReport.mainEngineFuelConsumptionRateBaseline_t_per_d) * 100 > 10,
      }">
      Summary
    </h2>
  </div>

  <div class="summaryContainer">
    <div class="pl-2 pr-3">
      <div class="property side-by-side separator">
        <div>Hull Paint Applied</div>
        <div class="value">{{ hullConditionReport.hullPaint }}</div>
      </div>

      <div class="property side-by-side separator">
        <div>Baseline M/E FCR [t/d]</div>
        <div class="value">{{ hullConditionReport.mainEngineFuelConsumptionRateBaseline_t_per_d | number: ".1" }}</div>
      </div>

      <div class="property side-by-side separator">
        <div>Latest M/E Overcons. [t/d]</div>
        <div class="value">{{ hullConditionReport.mainEngineFuelOverconsumption_t_per_d | number: ".1" }}</div>
      </div>

      <div class="property side-by-side separator">
        <div>Latest M/E Overcons. [%]</div>
        <div class="value" *ngIf="hullConditionReport.mainEngineFuelOverconsumption_t_per_d">
          {{
            (hullConditionReport.mainEngineFuelOverconsumption_t_per_d / hullConditionReport.mainEngineFuelConsumptionRateBaseline_t_per_d) * 100
              | number: "1.0-1"
          }}
        </div>
        <div class="value" *ngIf="!hullConditionReport.mainEngineFuelOverconsumption_t_per_d">
          {{ "" }}
        </div>
      </div>

      <div class="property side-by-side separator">
        <div>Remarks</div>
        <div class="value">{{ hullConditionReport.remark }}</div>
      </div>
    </div>
    <div class="pl-2 pr-3">
      <div class="property side-by-side separator">
        <div>Last Drydock</div>
        <div class="value">{{ hullConditionReport.lastDryDockDate_iso }}</div>
      </div>

      <div class="property side-by-side separator">
        <div>Hull Cleans since last Drydock</div>
        <div class="value">{{ hullConditionReport.hullCleansSinceLastDryDock }}</div>
      </div>

      <div class="property side-by-side separator">
        <div>Hull Cleaning Dates</div>
        <div class="value">
          <ul>
            <li *ngFor="let cleaningDate of hullConditionReport.hullCleaningDatesSinceLastDryDock_iso">
              {{ cleaningDate }}
            </li>
          </ul>
        </div>
      </div>

      <div class="property side-by-side separator">
        <div>Propeller Cleans since last Drydock</div>
        <div class="value">{{ hullConditionReport.propellerCleansSinceLastDryDock }}</div>
      </div>

      <div class="property side-by-side separator">
        <div>Propeller Cleaning Dates</div>
        <div class="value">
          <ul>
            <li *ngFor="let cleaningDate of hullConditionReport.propellerCleaningDatesSinceLastDryDock_iso">
              {{ cleaningDate }}
            </li>
          </ul>
        </div>
      </div>

      <div class="property side-by-side separator">
        <div>Scheduled Drydock</div>
        <div class="value">{{ hullConditionReport.scheduledDryDockDate_iso | date: "MMMM-yy" }}</div>
      </div>
    </div>
  </div>
</div>
