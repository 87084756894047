<div class="all">
  <div class="timeline">
    <div class="separator">
      <h2 class="pl-2">Timeline</h2>
    </div>
    <div class="overflow-y-auto pl-2 pr-3 pt-2" [ngClass]="{ spinner: fuelConsumptionTrendsOptions === undefined }">
      <highcharts-chart *ngIf="fuelConsumptionTrendsOptions" class="block h-full w-full" [Highcharts]="Highcharts" [options]="fuelConsumptionTrendsOptions">
      </highcharts-chart>
    </div>
    <div class="overflow-y-auto pl-2 pr-3 pt-2" [ngClass]="{ spinner: eventsOptions === undefined }">
      <highcharts-chart *ngIf="eventsOptions" class="block h-full w-full" [Highcharts]="Highcharts" [options]="eventsOptions"> </highcharts-chart>
    </div>
    <div class="overflow-y-auto pl-2 pr-3 pt-2" [ngClass]="{ spinner: bubbleOptions === undefined }">
      <highcharts-chart *ngIf="bubbleOptions" class="block h-full w-full" [Highcharts]="Highcharts" [options]="bubbleOptions"> </highcharts-chart>
    </div>
  </div>
  <div class="map">
    <div class="separator">
      <h2 class="pl-2">Map</h2>
    </div>
    <div class="overflow-y-auto pl-2 pr-3 pt-2" [ngClass]="{ spinner: mapOptions === undefined }">
      <highcharts-chart *ngIf="mapOptions" class="block h-full w-full" [Highcharts]="Highcharts" [options]="mapOptions"> </highcharts-chart>
    </div>
  </div>
</div>
