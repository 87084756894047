import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { VesselGroupsService } from "@app/services/vessel-groups/vessel-groups.service";
import { VESSEL_AWARE_ROUTEPARTS } from "@app/core/constants/constants";
import { MenuItem } from "primeng/api";

@Component({
  selector: "app-nav-vessels",
  templateUrl: "./nav-vessels.component.html",
  styleUrls: ["./nav-vessels.component.scss"],
})
export class NavVesselsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() vesselNavIsVisible: boolean;

  private selectedVesselId: number | null = NaN;
  private urlSegment: string;
  private subscriptions: Subscription[] = [];
  private items: MenuItem[] = [];

  constructor(
    private router: Router,
    private vesselGroupsService: VesselGroupsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.vesselGroupsService.initialize().subscribe({
        next: () => {
          const vesselGroups = this.vesselGroupsService.getVesselGroups();
          this.items = [];
          vesselGroups.forEach((group) => {
            const vessels = group._embedded["fo:vessels"];
            if (vessels && vessels.length > 0) {
              const groupItem = {
                id: group.id,
                label: group.name,
                expanded: true,
                items: [],
              };
              vessels.forEach((vessel) => {
                groupItem.items.push({
                  id: vessel.id,
                  label: vessel.name,
                  routerLink: `/${this.urlSegment}/${vessel.id}`,
                });
              });
              this.items.push(groupItem);
            }
          });
        },
      }),

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.onUrlUpdate(this.router.url);
        }
      })
    );

    this.onUrlUpdate(this.router.url);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.vesselNavIsVisible?.currentValue) {
      this.navigateScrollbarTo(this.vesselGroupsService.getSelectedVesselId());
    }
  }

  isLoading() {
    return this.vesselGroupsService.loading;
  }

  getItems(): MenuItem[] {
    return this.items;
  }

  private deselectVessel() {
    this.selectedVesselId = NaN;
    this.vesselGroupsService.setSelectedVesselId(NaN);
    this.router.navigate([`${this.urlSegment}`]);
  }

  private onUrlUpdate(url: string) {
    const urlSegments = this.getUrlSegments(url);
    const urlSegment = urlSegments[0];
    const selectedVesselId = parseInt(urlSegments[1], 10);
    this.navigateScrollbarTo(selectedVesselId);
    this.vesselGroupsService.setSelectedVesselId(selectedVesselId);
    if (urlSegment === this.urlSegment && this.selectedVesselId === selectedVesselId) {
      this.deselectVessel();
    } else {
      this.selectedVesselId = selectedVesselId;
    }
    this.urlSegment = VESSEL_AWARE_ROUTEPARTS.includes(urlSegment) ? urlSegment : "map";
    this.updateRouterLink();
  }

  private navigateScrollbarTo(vesselId: number) {
    if (!vesselId || !this.vesselNavIsVisible) {
      return;
    }
    const element = document.getElementById(vesselId.toString());
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    } else {
      // retry as long as the nav bar is not filled after loading the page
      setTimeout(() => this.navigateScrollbarTo(vesselId), 100);
    }
  }

  private updateRouterLink() {
    this.items.forEach((group) => group.items.forEach((vessel) => (vessel.routerLink = `/${this.urlSegment}/${vessel.id}`)));
    this.items = this.items.map((item) => item); // create a new array so that angular updates the view
  }

  private getUrlSegments(url: string): string[] {
    return url.replace(/^\/+/g, "").split("/");
  }
}
