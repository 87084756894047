<div class="flex flex-row full-screen w-screen">
  <p-toast></p-toast>

  <aside *ngIf="authService.isAuthenticated()"
         class="align-items-center bg-primary flex flex-column gap-inner p-default"
         [ngClass]="{'hide': !showVesselNav}">
    <img alt="Albis-Logo" class="h-5rem" src="/assets/img/logo-albis-white.svg">
    <app-nav-account></app-nav-account>
    <app-nav-vessels class="overflow-y-auto scrollbar-white" [vesselNavIsVisible]="showVesselNav"></app-nav-vessels>
  </aside>

  <main class="flex flex-column flex-auto flex-shrink-0"
        [ngClass]="{'with-vessel-nav': authService.isAuthenticated() && showVesselNav}">
    <header *ngIf="authService.isAuthenticated()" class="align-items-center flex gap-inner phor-default">
      <p-toggleButton [(ngModel)]="showVesselNav" offIcon="albis-icons-ship" onIcon="albis-icons-ship"
                      [pTooltip]="showVesselNav? 'Hide vessel navigation' : 'Show vessel navigation'"
                      tooltipPosition="bottom">
      </p-toggleButton>
      <app-nav-main class="overflow-x-auto"></app-nav-main>
    </header>
    <div class="content-height" [ngClass]="{'with-header': authService.isAuthenticated()}">  <!-- necessary for map -->
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
