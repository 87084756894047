import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as Highcharts from 'highcharts';

import {Dates} from 'app/helpers/dates';

import {DashboardComponent} from 'app/components/dashboard/dashboard.component';
import {Subscription} from 'rxjs';
import {ChartConfig, ChartsService} from '@app/services/charts/charts.service';
import {MenuItem, SelectItemGroup} from "primeng/api";

Highcharts.setOptions({
  credits: {enabled: false}
});

@Component({
  selector: 'app-chart-x-y',
  templateUrl: './chart-x-y.component.html',
  styleUrls: ['./chart-x-y.component.scss']
})

export class ChartXYComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dashboard: DashboardComponent;
  @Input() config: ChartConfig;
  @Input() dataGroups: any;

  readonly options: MenuItem[] = [
    {label: 'Edit', command: () => this.enterEditMode()},
  ];

  Highcharts = Highcharts;
  inited = false;
  zoomed = false;
  edit = false;
  submitted = false;
  loading = true;
  chartOptions: any;
  editModel: {
    title: string,
    position: string,
    dataTrackIdX: number,
    dataTrackIdY: number
  };
  dataGroupModel: SelectItemGroup[];

  private chartWithDataSubscription: Subscription;
  private chart: Highcharts.Chart;

  displayMessage: string = "";
  isChartError: boolean = false;

  constructor(private chartsService: ChartsService) {
  }

  ngOnInit() {
    this.loadChartData();
  }

  private loadChartData() {
    this.chartsService.getChartWithData(this.config).subscribe({
      next: (chartConfig) => {
        this.config = chartConfig;
        this.updateChartOptions();
        this.inited = true;
        this.loading = chartConfig.loading;
        this.edit = false;
        this.submitted = false;
        this.displayMessage = chartConfig.message;
      },
      error: (err) => {
        this.isChartError = true;
        this.loading = err.loading;
        this.displayMessage = err.message;
      }
    });
  }

  ngOnDestroy() {
    if (this.chartWithDataSubscription) {
      this.chartWithDataSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataGroups == undefined) {
      return;
    }
    this.dataGroupModel = [];
    changes.dataGroups.currentValue.forEach((dataGroup) => {
      const items = [];
      dataGroup.dataTracks.forEach((dataTrack) => {items.push({label: dataTrack.name, value: dataTrack.dbId});})
      this.dataGroupModel.push({label: dataGroup.title, items: items})
    })
  }

  chartInited(chart: Highcharts.Chart) {
    if (!chart.renderer['forExport']) {
      this.chart = chart;
    }
  }

  onChartSelection(event: any) {
    this.zoomed = !event.resetSelection;
  }

  resetChartSelection() {
    this.chart.zoomOut();
  }

  enterEditMode() {
    this.editModel = {
      title: this.config.title,
      position: this.config.position,
      dataTrackIdX: this.config.dataTrackX ? this.config.dataTrackX.dbId : null,
      dataTrackIdY: this.config.dataTrackY ? this.config.dataTrackY.dbId : null
    };
    this.submitted = false;
    this.edit = true;
    this.dashboard.editWidget(this.editModel.position);
  }

  resetFormOption(key: string) {
    this.editModel[key] = null;
  }

  submitForm(valid: boolean) {
    this.submitted = true;
    if (!valid) {
      return;
    }
    this.loading = true;

    this.chartsService.updateChart(this.config, this.editModel).toPromise().then(configData => {
      // maybe this can also be handled during the return of data inside subject.next - See ngOnInit
      this.dashboard.editWidget(this.editModel.position, false);
    });
  }

  cancelForm() {
    this.edit = false;
    this.submitted = false;
    this.dashboard.editWidget(this.editModel.position, false);
    this.updateChartOptions();
  }

  private updateChartOptions() {
    const series = {
      name: this.config.title,
      type: 'scatter',
      data: this.config.values || [],
      color: 'rgba(0, 62, 126, 1)',
      marker: {
        states: {
          hover: {
            fillColor: 'rgba(0, 62, 126, 1)',
          }
        }
      }
    };

    this.chartOptions = {
      title: this.config.title,
      styledMode: true,
      chart: {
        zoomType: 'xy',
        events: {
          selection: (event: any) => this.onChartSelection(event)
        },
        style: {
          fontFamily: 'Heebo',
          fontSize: '1rem'
        }
      },
      plotOptions: {
        series: {
          turboThreshold: 0
        },
        scatter: {
          marker: {
            symbol: 'circle',
            radius: 2
          },
          states: {
            hover: {
              halo: {
                size: 6
              }
            }
          }
        }
      },
      xAxis: [{
        title: null,
        name: this.config.dataTrackX.name,
        dataUnit: this.config.dataTrackX.dataUnit,
        labels: {
          format: '{value}' + this.config.dataTrackX.dataUnit,
          style: {
            color: '#666666',
            fontSize: '0.9rem'
          }
        },
        lineColor: '#ccd6eb',
        tickColor: '#ccd6eb',
      }],
      yAxis: [{
        title: null,
        name: this.config.dataTrackY.name,
        dataUnit: this.config.dataTrackY.dataUnit,
        labels: {
          format: '{value}' + this.config.dataTrackY.dataUnit,
          style: {
            color: '#666666',
            fontSize: '0.9rem'
          }
        },
        lineColor: '#ccd6eb'
      }],
      legend: {
        enabled: false
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderRadius: 3,
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        formatter: function () {
          return '<span class="highcharts-tooltip-headline">' + Dates.utcTimestampToUtcString(this.point.measuredAt) + '</span>'
            + '<span class="highcharts-tooltip-row">'
            + '<span class="highcharts-tooltip-icon highcharts-tooltip-icon-scatter" style="background-color:' + this.series.color + '"></span>'
            + this.series.xAxis.options.name + ': ' + this.x + this.series.xAxis.options.dataUnit
            + '</span>'
            + '<span class="highcharts-tooltip-row">'
            + '<span class="highcharts-tooltip-icon"></span>'
            + this.series.yAxis.options.name + ': ' + this.y + this.series.yAxis.options.dataUnit
            + '</span>';
        }
      },
      series: [series]
    };
  }
}
