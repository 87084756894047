export abstract class MapDrawComponent {
  protected static readonly LAYER_BASE = "https://maps.omniscale.net/v2/albis-software-key-31464f19/style.default/{z}/{x}/{y}.png";
  protected static readonly LAYER_SEAMARK = "https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png";
  protected static readonly ZOOM_STORAGE_KEY = "map.zoom";

  abstract drawVessels(vessels: any): void;

  abstract moveTo(vessel: any): void;

  /** Must be called after moveTo has finished. */
  abstract drawSelectedVessel(): void;

  abstract clearSelectedVessel(): void;

  abstract clearTrail(): void;

  abstract drawTrail(): void;

  abstract startRoute(): void;

  abstract stopRoute(): void;
}
