<div class="content">
  <ng-template #selectVessel>
    <h1>Please select a vessel</h1>
  </ng-template>

  <div *ngIf="getSelectedVesselId(); else selectVessel">
    <h1>{{ selectedVessel ? selectedVessel.name : "" }}</h1>

    <div class="flex flex-column gap-grid">
      <app-hull-condition-report class="flex-auto" [hullConditionReport]="hullConditionReport"> </app-hull-condition-report>

      <app-hull-condition-tracks [hullConditionTracks]="hullConditionTracks"> </app-hull-condition-tracks>

      <div class="flex flex-column lg:flex-row gap-grid">
        <app-hull-condition-remarks class="flex-auto" [hullConditionRemarks]="hullConditionRemarks" (addRemarkEvent)="addRemark($event)">
        </app-hull-condition-remarks>
      </div>

      <app-hull-condition-files [hullConditionFiles]="hullConditionFiles" [downloadHref]="hullConditionFilesDownloadHref"> </app-hull-condition-files>
    </div>
  </div>
</div>
