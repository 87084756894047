import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as Highcharts from 'highcharts';

import {Dates} from 'app/helpers/dates';

import {DashboardComponent} from 'app/components/dashboard/dashboard.component';
import {ChartConfig, ChartsService} from '@app/services/charts/charts.service';
import {Subscription} from 'rxjs';
import {MenuItem, SelectItemGroup} from "primeng/api";

import HighchartsBoost from "highcharts/modules/boost";

HighchartsBoost(Highcharts);
Highcharts.setOptions({
  credits: {enabled: false}
});

@Component({
  selector: 'app-chart-y-t',
  templateUrl: './chart-y-t.component.html',
  styleUrls: ['./chart-y-t.component.scss']
})

export class ChartYTComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dashboard: DashboardComponent;
  @Input() config: ChartConfig;
  @Input() dataGroups: any;

  readonly options: MenuItem[] = [
    {label: 'Edit', command: () => this.enterEditMode()},
  ];

  Highcharts = Highcharts;
  inited = false;
  zoomed = false;
  edit = false;
  submitted = false;
  loading = true;
  chartOptions: any;
  editModel: {
    title: string,
    position: string,
    dataTrackId1: number,
    dataTrackId2: number,
    dataTrackId3: number,
    dataTrackId4: number,
    dataTrackId5: number,
    dataTrackId6: number
  };
  dataGroupModel: SelectItemGroup[];

  private chartWithDataSubscription: Subscription;
  private chart: Highcharts.Chart;

  displayMessage: string = "";
  isChartError: boolean = false;

  constructor(private chartsService: ChartsService) {
  }

  ngOnInit() {
    this.loadChartData();
  }

  private loadChartData() {
    this.chartsService.getChartWithData(this.config).subscribe({
      next: (chartConfig) => {
        this.config = chartConfig;
        this.updateChartOptions();
        this.inited = true;
        this.loading = chartConfig.loading;
        this.edit = false;
        this.submitted = false;
        this.displayMessage = chartConfig.message;
      },
      error: (err) => {
        this.isChartError = true;
        this.loading = err.loading;
        this.displayMessage = err.message;
      }
    });
  }

  ngOnDestroy() {
    if (this.chartWithDataSubscription) {
      this.chartWithDataSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataGroups == undefined) {
      return;
    }
    this.dataGroupModel = [];
    changes.dataGroups.currentValue.forEach((dataGroup) => {
      const items = [];
      dataGroup.dataTracks.forEach((dataTrack) => {items.push({label: dataTrack.name, value: dataTrack.dbId});})
      this.dataGroupModel.push({label: dataGroup.title, items: items})
    })
  }

  chartInited(chart: Highcharts.Chart) {
    if (!chart.renderer['forExport']) {
      this.chart = chart;
    }
  }

  onChartSelection(event: any) {
    this.zoomed = !event.resetSelection;
  }

  resetChartSelection() {
    this.chart.zoomOut();
  }

  enterEditMode() {
    this.editModel = {
      title: this.config.title,
      position: this.config.position,
      dataTrackId1: this.config.dataTrack1 ? this.config.dataTrack1.dbId : null,
      dataTrackId2: this.config.dataTrack2 ? this.config.dataTrack2.dbId : null,
      dataTrackId3: this.config.dataTrack3 ? this.config.dataTrack3.dbId : null,
      dataTrackId4: this.config.dataTrack4 ? this.config.dataTrack4.dbId : null,
      dataTrackId5: this.config.dataTrack5 ? this.config.dataTrack5.dbId : null,
      dataTrackId6: this.config.dataTrack6 ? this.config.dataTrack6.dbId : null
    };
    this.submitted = false;
    this.edit = true;
    this.dashboard.editWidget(this.editModel.position);
  }

  resetFormOption(key: string) {
    this.editModel[key] = null;
  }

  submitForm(valid: boolean) {
    this.submitted = true;
    if (!valid) {
      return;
    }
    this.loading = true;

    this.chartsService.updateChart(this.config, this.editModel).toPromise().then(configData => {
      // maybe this can also be handled during the return of data inside subject.next - See ngOnInit
      this.dashboard.editWidget(this.editModel.position, false);
    });
  }

  cancelForm() {
    this.edit = false;
    this.submitted = false;
    this.dashboard.editWidget(this.editModel.position, false);
    this.updateChartOptions();
  }

  private updateChartOptions() {
    const series: any = [],
      yAxes: { [key: string]: any; }[] = [];

    for (let i = 1; i < 7; i++) {
      const dataTrack = this.config['dataTrack' + i];
      if (!dataTrack) {
        continue;
      }

      let yAxisIndex = yAxes.findIndex((axis) => {
        return axis.dataUnit === dataTrack.dataUnit;
      });
      if (yAxisIndex < 0) {
        yAxisIndex = yAxes.push({
          title: null,
          dataUnit: dataTrack.dataUnit,
          labels: {
            format: '{value}' + dataTrack.dataUnit,
            style: {
              color: '#666666',
            }
          },
          lineColor: '#ccd6eb'
        }) - 1;
      }

      const dataTrackValues = dataTrack.values || [],
        dataTrackSeries: { [key: string]: any; } = {
          boostThreshold: 1,
          name: dataTrack.title,
          tooltip: {valueSuffix: dataTrack.dataUnit},
          data: [],
          yAxis: yAxisIndex
        };

      if (i === 1) {
        Object.assign(dataTrackSeries, {
          type: 'column',
          color: 'rgba(206, 214, 220, 1)',
        });
      } else if (i === 2) {
        Object.assign(dataTrackSeries, {
          type: 'column',
          color: 'rgba(224, 235, 243, 1)',
        });
      } else if (i === 3) {
        Object.assign(dataTrackSeries, {
          type: 'line',
          color: 'rgba(0, 62, 126, 1)',
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2
          }
        });
      } else if (i === 4) {
        Object.assign(dataTrackSeries, {
          type: 'line',
          color: 'rgba(104, 162, 66, 1)',
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2
          }
        });
      } else if (i === 5) {
        Object.assign(dataTrackSeries, {
          type: 'line',
          color: 'rgba(237, 28, 36, 1)',
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2
          }
        });
      } else if (i === 6) {
        Object.assign(dataTrackSeries, {
          type: 'line',
          color: 'rgba(253,185,0,1)',
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2
          }
        });
      }

      for (const point of dataTrackValues) {
        dataTrackSeries.data.push([
          point.measuredAt,
          point.value
        ]);
      }

      series.push(dataTrackSeries);

    }

    this.chartOptions = {
      boost: {
        useGPUTranslations: true,
        seriesThreshold: 1
      },
      title: this.config.title,
      styledMode: true,
      chart: {
        zoomType: 'x',
        events: {
          selection: (event: any) => this.onChartSelection(event)
        },
        style: {
          fontFamily: 'Heebo',
          fontSize: '1rem'
        }
      },
      plotOptions: {
        series: {
          lineWidth: 1
        },
        line: {
          states: {
            hover: {
              halo: {
                size: 6
              }
            }
          }
        },
        column: {
          states: {
            hover: {
              brightness: -0.05
            }
          }
        }
      },
      xAxis: [{
        labels: {
          style: {
            color: '#666666',
          }
        },
        lineColor: '#ccd6eb',
        tickColor: '#ccd6eb',
        type: 'datetime'
      }],
      yAxis: yAxes,
      legend: {
        align: 'left',
        alignColumns: true,
        verticalAlign: 'top',
        symbolHeight: 0,
        symbolWidth: 0,
        symbolPadding: 0,
        itemMarginTop: 2,
        itemMarginBottom: 0,
        itemStyle: {
          'color': '#111111',
          'font-weight': 'normal',
        },
        itemHiddenStyle: '',
        useHTML: true,
        labelFormatter: function () {
          return '<span class="highcharts-legend-icon" style="background-color:' + this.color + '"></span>' + this.name;
        }
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0 )',
        borderRadius: 3,
        borderWidth: 0,
        shadow: false,
        shared: true,
        useHTML: true,
        formatter: function () {
          return this.points.reduce(function (s: any, point: any) {
            return s
              + '<span class="highcharts-tooltip-row">'
              + '<span class="highcharts-tooltip-icon highcharts-tooltip-icon-' + point.series.type + '" style="background-color:' + point.series.color + '"></span>'
              + point.series.name + ': ' + point.y + point.series.tooltipOptions.valueSuffix
              + '</span>';
          }, '<span class="highcharts-tooltip-headline">' + Dates.utcTimestampToUtcString(this.x) + '</span>');
        }
      },
      series: series,
    };
  }

}
