import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  public invalidVessel: boolean = false;
 
  constructor(
    private route: ActivatedRoute
  ) {}
 
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.invalidVessel = params['invalidVessel'] === 'true';
    });
  }
}
